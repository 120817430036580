<template>
    <section class="circle circle__high">
        <section class="circle circle__mid">
            <img src="./assets/check.svg" alt="check grenn icon">
        </section>
    </section>
</template>
<script>
export default {
    
}
</script>
<style lang="scss">
.circle { 
    display: flex;
    align-items: center;
    justify-content: center;

    &__high {
        width: 140px;
        height: 140px;
        background: linear-gradient(307deg, #ACFFAC 8.76%, rgba(172, 255, 172, 0.00) 152.25%);
        border-radius: 50%;
    }

    &__mid {
        width: 110px;
        height: 110px;
        border: 10px solid #61FD61;
        border-radius: 50%;
    }
}
</style>