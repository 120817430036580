<template>
  <b-modal
    id="onboard-indications"
    name="onboard-indications"
    size="md"
  >
    <template v-slot:modal-title>
      <h5 class="mt-4 onboard-indications-title">Greenn Partner 🎉</h5>
      <small class="onboard-indications-subtitle">
        {{ $t('seller.indication.text_2873') }}
      </small>
    </template>

    <b-row>
      <b-col cols="12">
        <div class="d-flex">
          <div class="mr-3 w-full">
            <img src="@/assets/img/icons/indication_onboard.png" style="min-width: 65px;" alt="indication_onboard" />
          </div>
          <div>
            <p class="mt-1 onboard-indications-text">{{ $t('seller.indication.text_2874') }}</p>
            <p class="mt-4 pb-1 onboard-indications-text">{{ $t('seller.indication.text_2875') }}</p>
            <p class="mt-4 onboard-indications-text">{{ $t('seller.indication.text_2876') }}</p>
          </div>
        </div>
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ cancel }">
      <div class="d-flex justify-content-end mb-4 w-100">
        <div class="d-md-flex w-100">
          <BaseButton variant="gray" class="mr-3 mb-2 w-100" @click="cancel">
            {{ $t('seller.indication.text_2877') }}
          </BaseButton>
          <BaseButton variant="dark-green" @click="onSubmit" class="w-100" style="min-width: 229px;">
            {{ $t('seller.indication.text_2878') }}
          </BaseButton>
        </div>
      </div>
      <!-- <div class="w-100 d-flex justify-content-center">
        <small class="cursor-pointer" @click="updateIndicationOnboard">{{ $t('seller.indication.text_2879') }}</small>
      </div> -->
    </template>
  </b-modal>
</template>

<script>
import UserSettingsService from '@/services/resources/UserSettingsService'
const serviceUserSettings = UserSettingsService.build()

export default {
  name: 'ModalIndicationsOnboard',
  methods: {
    onSubmit() {
      this.updateIndicationOnboard()
      this.$bvModal.hide("onboard-indications")
      this.$router.push('/indications')
    },
    updateIndicationOnboard() {
      let data = {
        key: 'INDICATION_ONBOARD',
        value: 'COMPLETED'
      }

      serviceUserSettings.create(data, '/create-unique')
        .then(() => this.$bvModal.hide("onboard-indications"))
        .catch(err => console.error(err))
    }
  }
}
</script>

<style>
.modal-content .modal-header {
  border-bottom: 1px solid #fff !important;
}
.modal-content .modal-footer {
  border-top: 1px solid #fff !important;
}
.onboard-indications-title {
  color: #333333;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 150%;
}
.onboard-indications-subtitle {
  color: #81858E;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
}
.onboard-indications-text {
  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
}
#onboard-indications___BV_modal_content_ {
  margin-top: 78px;
}
</style>