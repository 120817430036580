import Api from '@/services/API';

/**
 * @typedef {AuthRequest}
 */

export default class Auth extends Api {

    /**
     * @type {String}
     */
    static base = '/api/login/club'


    /**
     * @type {String}
     */
    static resource = ''

    /**
     * @param {String} resource
     * @param {Object} options
     * @param {Object} http
     */
    constructor(resource, options = {}, http = null) {
        super(Auth.normalize(Auth.base, resource), options, http);
    }

    /**
     * @return {this}
     */
    static build() {
        return new this(this.resource);
    }

    login(username, password, captcha) {

        let data = {
            username: username,
            password: password,
            captcha: captcha,
            grant_type: 'password',
            scope: '*',
            client_id: process.env.VUE_APP_OAUTH_CLIENT_id,
            client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET
        }

        return this.post('', data);
    }

    toClub() {
        return this.post('');
    }

    logout() {
        return this.post();
    }

}