<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
import moment from "moment";

import { Line } from "vue-chartjs";
import Money from "@/mixins/money";

Chart.defaults.global.defaultFontFamily = "'Montserrat'";
Chart.defaults.global.defaultFontSize = 11;

export default {
  name: "AreaChart",
  extends: Line,
  mixins: [Money],
  props: {
    currency: {
      required: true,
      type: String,
      default: "R$"
    }
  },
  data() {
    return {
      gradient: null,
      gradient2: null,
      vendas_semana: [],
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    mountedChart(vendas_semana) {
      this.vendas_semana = vendas_semana;
      this.gradient = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);

      this.gradient.addColorStop(0, this.isHeaven ? '#0070EB' : 'rgba(0, 148, 136, 0.4)');
      this.gradient.addColorStop(0.5, this.isHeaven ? '#0072eb48' : 'rgba(0,228,160,.2)');
      this.gradient.addColorStop(1, this.isHeaven ? '#0072eb17' : 'rgba(0,228,160,0)');

      this.renderChart(
        {
          labels: [
            ...this.vendas_semana
              .reverse()
              .map((item) => moment(item.date).format("DD/MM")),
          ],
          datasets: [
            {
              label: this.$t('chart.dataset_label') + this.currency,
              borderColor: this.isHeaven ? '#0070EB' : '#009488',
              pointBackgroundColor: '#FFF',
              borderWidth: 1.5,
              pointBorderColor: this.isHeaven ? '#0070EB' : '#009488',
              backgroundColor: this.gradient,
              data: [
                ...this.vendas_semana
                  .map((item) => item.total),
              ],
            },
          ],
        },
        {
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var currentValue = dataset.data[tooltipItem.index];
                return currentValue.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                });
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  color: "#f7f7f7",
                },
                ticks: {
                  min: 0,
                  display: true
                }
              }
            ]
          }
        }
      )
    }
  }
}
</script>

<style>

.teste{
  color: #0070EB;
  color: #0072eb48;
  color: #0072eb17;

  color: rgba(0,228,160,1);
  color: rgba(0,228,160,.2);
  color: rgba(0,228,160,0);
}
</style>
