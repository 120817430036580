<template>
  <div class="card_want_sale ml-md-2 d-flex justify-content-center align-items-center" :class="{'heaven': isHeaven}">
    <div class="mr-2">
      <p class="mb-2" style="color:white;font-weight:600;">{{ $t('views.buyer.dashboard.text_121') }} {{ isHeaven ? 'Heaven' : 'Greenn' }}</p>
      <p style="color:white;">{{ $t('views.buyer.dashboard.text_122') }}</p>
      <div class="mt-3">
        <BaseButton
          :outline="true"
          variant="white2"
          style="height: 40px !important;"
          @click="$router.push({ path: '/register-seller' })"
        >
          {{ $t('views.dashboard.text_1000') }}
        </BaseButton>
      </div>
    </div>
    <img v-if="!isHeaven" src="@/assets/img/icons/want_sale.svg" height="107" alt="want_sale" style="margin-bottom:10px;">
    <img v-else src="@/assets/img/icons-heaven/want_sale.svg" height="107" alt="want_sale" style="margin-bottom:10px;">
  </div>
</template>

<script>
export default {
  name: 'CardWantBeSeller',
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  }
}
</script>

<style scoped>
.card_want_sale {
  width: 100%;
  height: 170px;
  border: 1px;
  border-radius: 10px;
  background-color: #00A650;
  color: white;
  padding: 27px 26px 26px 35px;
}
.card_want_sale.heaven {
  width: 100%;
  height: 170px;
  border: 1px;
  border-radius: 10px;
  background-color: #1964D6;
  color: white;
  padding: 27px 26px 26px 35px;
}
</style>